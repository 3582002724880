import React, { Component } from 'react'
import ReactPlayer from 'react-player'
import './responsive-player.css'

let poster, video_mp4, video_webm, video_ogv;

class Videoplayer extends Component {

  constructor() {      
    super();
    this.state = { 
        url: [],         
        video_mp4: '',
        video_webm: '',
        video_ogv: '',
        videos: "",
        poster: '',
        isloaded: false,
        playing: false
    };                
  }

  componentDidMount = () => {    

    // console.log(video_webm);
    this.handleReady();
  }

  handleReady = (event) => {    
    video_mp4 = document.getElementsByClassName('video')[0].dataset.videoMp4;
    video_webm = document.getElementsByClassName('video')[0].dataset.videoWebm;
    video_ogv = document.getElementsByClassName('video')[0].dataset.videoOgv;
    poster = document.getElementsByClassName('video')[0].dataset.poster;    
    this.setState({      
      poster, 
      playing: true,
      isloaded: true,      
    });
    const url = [video_mp4, video_ogv, video_webm ];
    this.load(url);
  }
  
  load = url => {
    // console.log(url)    
    this.setState({
      url     
    })
  }

  
  render () {    
    const { url, isloaded, playing, poster } = this.state    
    return (
      <div className='player-wrapper'>
        <ReactPlayer 
          className='react-player'          
          url={isloaded?url:null}
          width="100%"
          height="100%"
          light={poster}
          controls={true}
          playing={playing}
        />
      </div>
    );
  }

}

export default Videoplayer;