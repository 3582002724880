import React, { Component } from 'react';
// import logo from './logo.svg';
// import prodlogo from './prod-log.svg';
// import sleeptrackerMobile from './sleeptracker-mobile.svg';
import Desktop from './components/desktop/Desktop';
import Home from './components/home/Home';

import Coaching from './components/coaching/Coaching';
import Connect from './components/connect/Connect';
import Snore from './components/snore/Snore';
import Products from './components/products/Products';
import LangSwitcher from './components/setlang/langswitcher';
import Floatingmenu from './components/menu/floating-menu';

import './App.css';
let pageId;

class App extends Component {

  constructor() {      
      super();
      this.state = {          
          page: ''
      };                
  }
  
  componentDidMount = () => {
      // pageId = window.location.pathname;      
      if (window.location.href.indexOf("coaching") > -1) {
        pageId = 'coaching'
      }else if (window.location.href.indexOf("products") > -1) {
        pageId = 'products'
      }else if (window.location.href.indexOf("snore") > -1) {
        pageId = 'snore'
      }else if (window.location.href.indexOf("connect") > -1) {
        pageId = 'connect'
      }else if (window.location.href.indexOf("desktop") > -1) {
        pageId = 'desktop'
      }
      // console.log(`Path: ${pageId}`); 
      this.setState({
        page: pageId
      })
  }
  
    
  render() {        
    if (this.state.page === 'desktop') {           
      return (
        <React.Fragment>
          {/* <div className="App">
            <header className="App-header">
              <img src={logo} className="App-logo" alt="logo" />
              <p>
                Edit <code>src/App.js</code> and save to reload.
              </p>
                <a
                  className="App-link"
                  href="https://reactjs.org"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                Learn React
              </a>
            </header>      
        </div>   */}
          
          <Desktop />
        </React.Fragment>    
      );
    }else if (this.state.page === 'products'){      
      return (
        <React.Fragment><Products /><LangSwitcher /><Floatingmenu /></React.Fragment>
      );
    }else if (this.state.page === 'snore'){      
      return (
        <React.Fragment><Snore /><LangSwitcher /><Floatingmenu /></React.Fragment>
      );
    }else if (this.state.page === 'connect'){
      return (
        <React.Fragment><Connect /><LangSwitcher /><Floatingmenu /></React.Fragment>
      );
    }else if (this.state.page === 'coaching'){
      // console.log("In Coaching")
      return (
        <React.Fragment><Coaching /><LangSwitcher /><Floatingmenu /></React.Fragment>
      );
    }else{                    
      if (this.state.page != "") {
        return (
          <React.Fragment><Home /><LangSwitcher /><Floatingmenu /></React.Fragment>
        );
      }else {
        return (
          <React.Fragment><LangSwitcher /></React.Fragment>
        );
      }        
    }    
  }
}

export default App;
