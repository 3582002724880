import React, { Component } from 'react'
import Carousel from 'react-bootstrap/Carousel';
import './carousel.css'

class CarouselCoaching extends Component {
    constructor() {
        super();
        this.state = {
            index: 0,
            setIndex: 0,
            direction: null,
            setDirection: null,
            slide_1: '',
            slide_2: '',
            slide_3: '',
            caption_1: '',
            caption_2: '',
            caption_3: ''
        };
    }
    componentDidMount = () => {
        const slide_1 = document.getElementsByClassName('coaching__app')[0].dataset.imgOne;
        const slide_2 = document.getElementsByClassName('coaching__app')[0].dataset.imgTwo;
        const slide_3 = document.getElementsByClassName('coaching__app')[0].dataset.imgThree;
        const caption_1 = document.getElementsByClassName('coaching__app')[0].dataset.captionOne;
        const caption_2 = document.getElementsByClassName('coaching__app')[0].dataset.captionTwo;
        const caption_3 = document.getElementsByClassName('coaching__app')[0].dataset.captionThree;

        this.setState({
            slide_1,
            slide_2,
            slide_3,
            caption_1,
            caption_2,
            caption_3
        })

        // console.log(`image 1 ${slide_1}`);
    }
    
    setIndex = (index) => {
        //Implement method
        // console.log(`Index of Slide ${index}`);
        this.setState({
            setIndex: index,
            index
        })
        
    }

    setDirection = (direction) =>  {
        //Implement method
        // console.log(`Index Image Direction ${direction}`);
        this.setState({
            setDirection: direction,
            direction
        })                
    }

    handleSelect = (selectedIndex, e) => {        
        this.setIndex(selectedIndex);
        this.setDirection(e);        
    }

    render() {
        const {index, direction, slide_1, slide_2, slide_3, caption_1, caption_2, caption_3} =  this.state;
        return (
            <div>
                <Carousel interval={false} activeIndex={index} direction={direction} onSelect={this.handleSelect} controls={false} className="coaching-block">
                    <Carousel.Item className="screen__imageD">
                        <img
                            className="d-block w-100"
                            src={slide_1}
                            alt=""
                        />
                        <Carousel.Caption>            
                            <p>{caption_1}</p>
                        </Carousel.Caption>
                    </Carousel.Item>
                    <Carousel.Item className="screen__imageD">
                        <img
                            className="d-block "
                            src={slide_2}
                            alt=""
                        />
                
                        <Carousel.Caption>            
                            <p>{caption_2}</p>
                        </Carousel.Caption>
                    </Carousel.Item>
                    <Carousel.Item className="screen__imageD">
                        <img
                            className="d-block "
                            src={slide_3}
                            alt=""
                        />
                
                        <Carousel.Caption>            
                            <p>{caption_3}</p>
                        </Carousel.Caption>
                    </Carousel.Item>
                </Carousel>
            </div>
        );
    }
}

// function ControlledCarouselb() {
//     const [index, setIndex] = useState(0);
//     const [direction, setDirection] = useState(null);
  
//     const handleSelect = (selectedIndex, e) => {
//       setIndex(selectedIndex);
//       setDirection(e.direction);
//     };
    
    

    
//   }
  
// //   render(<ControlledCarousel />);

export default CarouselCoaching;