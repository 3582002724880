import React, { Component } from 'react'
import Carousel from 'react-bootstrap/Carousel';
import './carousel.css'

class CarouselConnect extends Component {
    constructor() {
        super();
        this.state = {
            index: 0,
            setIndex: 0,
            direction: null,
            setDirection: null,
            slide_1: '',
            slide_2: '',
            slide_3: '',
            slide_4: '',
            slide_5: '',
            caption_1: '',
            caption_2: '',
            caption_3: '',
            caption_4: '',
            caption_5: '',
            text_1: '',
            text_2: '',
            text_3: '',
            text_4: '',
            text_5: '',
            subtext_4: '',
            subtext_5: ''
        };
    }
    componentDidMount = () => {
        const slide_1 = document.getElementsByClassName('coaching__app')[0].dataset.imgOne;
        const slide_2 = document.getElementsByClassName('coaching__app')[0].dataset.imgTwo;
        const slide_3 = document.getElementsByClassName('coaching__app')[0].dataset.imgThree;
        const slide_4 = document.getElementsByClassName('coaching__app')[0].dataset.imgFour;
        const slide_5 = document.getElementsByClassName('coaching__app')[0].dataset.imgFive;
        const caption_1 = document.getElementsByClassName('coaching__app')[0].dataset.captionOne;
        const caption_2 = document.getElementsByClassName('coaching__app')[0].dataset.captionTwo;
        const caption_3 = document.getElementsByClassName('coaching__app')[0].dataset.captionThree;
        const caption_4 = document.getElementsByClassName('coaching__app')[0].dataset.captionFour;
        const caption_5 = document.getElementsByClassName('coaching__app')[0].dataset.captionFive;
        const text_1 = document.getElementsByClassName('coaching__app')[0].dataset.textOne;
        const text_2 = document.getElementsByClassName('coaching__app')[0].dataset.textTwo;
        const text_3 = document.getElementsByClassName('coaching__app')[0].dataset.textThree;
        const text_4 = document.getElementsByClassName('coaching__app')[0].dataset.textFour;
        const text_5 = document.getElementsByClassName('coaching__app')[0].dataset.textFive;
        const subtext_4 = document.getElementsByClassName('coaching__app')[0].dataset.subtextFour;
        const subtext_5 = document.getElementsByClassName('coaching__app')[0].dataset.subtextFive;

        this.setState({
            slide_1,
            slide_2,
            slide_3,
            slide_4,
            slide_5,
            caption_1,
            caption_2,
            caption_3,
            caption_4,
            caption_5,
            text_1,
            text_2,
            text_3,
            text_4,
            text_5,
            subtext_4,
            subtext_5
        })        
    }
    
    setIndex = (index) => {
        //Implement method        
        this.setState({
            setIndex: index,
            index
        })
        
    }

    setDirection = (direction) =>  {
        //Implement method        
        this.setState({
            setDirection: direction,
            direction
        })                
    }

    handleSelect = (selectedIndex, e) => {        
        this.setIndex(selectedIndex);
        this.setDirection(e);        
    }

    render() {
        const {index, direction, slide_1, slide_2, slide_3, slide_4, slide_5, caption_1, caption_2, caption_3, caption_4, caption_5, text_1, text_2, text_3, text_4, text_5, subtext_4, subtext_5} =  this.state;
        return (
            <div>
                <Carousel interval={false} activeIndex={index} direction={direction} onSelect={this.handleSelect} controls={false} className="carousel_con" fade={ true } slide={true}>
                    <Carousel.Item className="">
                        <img
                            className="d-block "
                            src={slide_1}
                            alt="First slide [800x400]"
                        />
                        <Carousel.Caption className="carousel-caption_con">     
                            <div className="slidetext">
                                <h3>{text_1}</h3>                            
                            </div>                            
                            <p>{caption_1}</p>
                        </Carousel.Caption>
                    </Carousel.Item>
                    <Carousel.Item className="">
                        <img
                            className="d-block "
                            src={slide_2}
                            alt=""
                        />                
                        <Carousel.Caption className="carousel-caption_con">    
                            <div className="slidetext">
                                <h3>{text_2}</h3>                            
                            </div>
                            <p>{caption_2}</p>
                        </Carousel.Caption>
                    </Carousel.Item>
                    <Carousel.Item className="">
                        <img
                            className="d-block "
                            src={slide_3}
                            alt=""
                        />                
                        <Carousel.Caption className="carousel-caption_con">                                        
                            <div className="slidetext">
                                <h3>{text_3}</h3>                            
                            </div>
                            <p>{caption_3}</p>
                        </Carousel.Caption>
                    </Carousel.Item>
                    <Carousel.Item className="">
                        <img
                            className="d-block "
                            src={slide_4}
                            alt=""
                        />                
                        <Carousel.Caption className="carousel-caption_con">                                        
                            <div className="slidetext">                                
                                <h3>{text_4}</h3>
                                <h4>{subtext_4}</h4>
                            </div>
                            <p>{caption_4}</p>
                        </Carousel.Caption>
                    </Carousel.Item>
                    <Carousel.Item className="">
                        <img
                            className="d-block "
                            src={slide_5}
                            alt=""
                        />                
                        <Carousel.Caption className="carousel-caption_con">                                        
                            <div className="slidetext">                                
                                <h3>{text_5}</h3>
                                <h4>{subtext_5}</h4>
                            </div>
                            <p>{caption_5}</p>
                        </Carousel.Caption>
                    </Carousel.Item>
                </Carousel>
            </div>
        );
    }
}

// function ControlledCarouselb() {
//     const [index, setIndex] = useState(0);
//     const [direction, setDirection] = useState(null);
  
//     const handleSelect = (selectedIndex, e) => {
//       setIndex(selectedIndex);
//       setDirection(e.direction);
//     };
    
    

    
//   }
  
// //   render(<ControlledCarousel />);

export default CarouselConnect;