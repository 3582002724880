import React, { Component } from 'react';

const scrollNav = document.getElementById("scroll__nav");
const footerCta = document.getElementById("footer-cta");
const footerCta_copy = document.getElementById("footer-cta-copy");
const googlehome = document.getElementById("score__response");

class Floatingmenu extends Component {
    constructor() {      
        super();
        this.state = {                      
            isVisible: false,
        };                
    }

    componentDidMount = () => {
        this.checkwindowScroll();
    }

    checkwindowScroll = () => {
        let _state = this.state;

        window.addEventListener("scroll", function() {  
            let ctaelement = document.getElementById("scroll__nav");
            const add_class_on_scroll = () => scrollNav.classList.add("scroll__nav--active");                        
            const remove_class_on_scroll = () => scrollNav.classList.remove("scroll__nav--active");            
            const remove_class_on_scroll_fr = () => scrollNav.classList.remove("scroll__nav_fr");      
            const cta_add_class_on_scroll = () => footerCta.classList.add("hide-cta");
            const cta_remove_class_on_scroll = () => footerCta.classList.remove("hide-cta");
            const add_class_on_scroll_send_to_back = () => scrollNav.classList.add("scroll__nav--send_to_back");
            const remove_class_on_scroll_send_to_back = () => scrollNav.classList.remove('scroll__nav--send_to_back');
            const add_class_on_scroll_hide_cta_copy = () => footerCta_copy.classList.add("footer__cta_hide");
            const remove_class_on_scroll_hide_cta_copy = () => footerCta_copy.classList.remove("footer__cta_hide");        
            let limit =  document.body.offsetHeight - window.innerHeight;
            // console.log(`Scroll Limit ${limit}px Scroll Y ${window.scrollY}`)
            // console.log(`Scrolling ${window.scrollY} OffsetTop ${ctaelement.offsetTop} offsetHeight ${ctaelement.offsetHeight} scrollHeight ${ctaelement.scrollHeight} clientheight ${ctaelement.clientHeight} scrolltop ${ctaelement.scrollTop}`)

            if (  (window.scrollY === '' && this.isVisible === false) || (window.scrollY >= 10 && this.isVisible === false))  {                                
                remove_class_on_scroll();   
                               
                cta_add_class_on_scroll();
            }else if ( limit - window.scrollY >= 298 ) {
                // console.log(`limit - window.scrollY ${limit - window.scrollY}`);
                add_class_on_scroll();    
                remove_class_on_scroll_send_to_back();                
                
                cta_add_class_on_scroll();            
                this.isVisible = true;
            }else{
                // add_class_on_scroll();
                add_class_on_scroll_send_to_back();    
                cta_remove_class_on_scroll();
                remove_class_on_scroll();                    
            }

            // if ( limit - window.scrollY == 0) {
                
            // }else{

            // }

            if ( limit - window.scrollY >= 260 && (window.location.href.indexOf("snore") > -1)) {                
                add_class_on_scroll_hide_cta_copy();
            }else if ( limit - window.scrollY >= 260 && (window.location.href.indexOf("coaching") > -1) ) {
                add_class_on_scroll_hide_cta_copy();
            }else if ( limit - window.scrollY >= 260 && (window.location.href.indexOf("connect") > -1) ) {
                add_class_on_scroll_hide_cta_copy();
            }else if ( limit - window.scrollY >= 260 && (window.location.href.indexOf("products") > -1) ) {
                add_class_on_scroll_hide_cta_copy();
            }else if ( limit - window.scrollY >= 240 && (window.location.pathname === '/en/' || window.location.pathname === '/fr/') ) {

            }else{
                remove_class_on_scroll_hide_cta_copy();
            }

            // if ( (window.innerHeight + window.scrollY) > (document.body.offsetHeight - 250)  || ((this.isVisible === false) && (window.scrollY > (elementTarget.offsetTop))) ) {                  
                
            //     remove_class_on_scroll();
            // }

            // if ((window.innerHeight + window.scrollY) >= (document.body.offsetHeight - 200 ) ) {                             
                
            //     cta_remove_class_on_scroll();
            //     this.isVisible = true;  
            // }else{
            //     cta_add_class_on_scroll();
            //     if ( ((window.innerHeight + window.scrollY) <= (document.body.offsetHeight - 250) ) && this.isVisible === true ) {                    
            //         add_class_on_scroll();
            //     }                    
            // }

        }.bind(_state), false);

    }

    render() {    
        return (
            <React.Fragment>          
            </React.Fragment>    
        );
    }

}

export default Floatingmenu;