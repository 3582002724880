import React, { Component } from 'react';

// const scrollNav = document.querySelector("scroll__nav");
const scrollNav = document.getElementById("scroll__nav");
const footerCta = document.getElementById("footer-cta");

class Home extends Component {
    constructor() {      
        super();
        this.state = {                      
            isVisible: false,
        };                
    }
    componentDidMount = () => {                
        window.onload = this.transition_text;
        // setTimeout(() => this.transition_text(), 2000);          
        this.windowscreenSize();
        if (window.location.pathname === '/en/' || window.location.pathname === '/fr/')
            this.checkwindowScroll();
    }    

    transition_text = () => {                        
        let heroLogo = document.getElementsByClassName('hero__logo');
        let heroHeadline = document.getElementsByClassName('hero__headline');
        let heroTextwhite = document.getElementsByClassName('hero__text--white');
        let heroTextgreen = document.getElementsByClassName('hero__text--green');
        let heroArrow = document.getElementsByClassName('hero__arrow');
        // setAttribute("style", "transition: opacity 0.5s ease-in 2s;opacity: 1");
        heroLogo[0].classList.add('hero__text--transition');
        heroHeadline[0].classList.add('hero__text--transition');
        heroTextwhite[0].classList.add('hero__text--transitionA');
        heroTextgreen[0].classList.add('hero__text--transitionB');
        heroArrow[0].classList.add('hero__text--transitionC');
    }

    checkwindowScroll = () => {
        let _state = this.state;
        window.addEventListener("scroll", function() {            
            let elementTarget = document.getElementById("home-hero");
            let ctaelement = document.getElementById("scroll__nav");
            const add_class_on_scroll = () => scrollNav.classList.add("scroll__nav--active");
            const remove_class_on_scroll = () => scrollNav.classList.remove("scroll__nav--active");
            const cta_add_class_on_scroll = () => footerCta.classList.add("hide-cta");
            const cta_remove_class_on_scroll = () => footerCta.classList.remove("hide-cta");
            // if (window.scrollY > (elementTarget.offsetTop + elementTarget.offsetHeight)) {
            //     console.log(`Scrolling ${window.scrollY} OffsetTop ${ctaelement.offsetTop} offsetHeight ${ctaelement.offsetHeight} scrollHeight ${ctaelement.scrollHeight} clientheight ${ctaelement.clientHeight} scrolltop ${ctaelement.scrollTop}`)
            // if (window.scrollY === 0)
            //     this.isVisible = false;

            // if ((window.innerHeight + window.scrollY) > (document.body.offsetHeight - 250)  || ( (this.isVisible === false) && (window.scrollY > (elementTarget.offsetTop)) ) ) {                  
                
            //     remove_class_on_scroll();
            // }

            // if ((window.innerHeight + window.scrollY) >= (document.body.offsetHeight - 200 ) ) {
            //     // you're at the bottom of the page
            //     // console.log(`window innerheight + window.scrollY`, (window.innerHeight + window.scrollY));
            //     // console.log(`body offset height`, document.body.offsetHeight);
            //     // console.log("cta reached", floatMenu)                
                
            //     cta_remove_class_on_scroll();
            //     this.isVisible = true;  
            // }else{
            //     cta_add_class_on_scroll();
            //     if ( ((window.innerHeight + window.scrollY) <= (document.body.offsetHeight - 250) ) && this.isVisible === true ) {                    
            //         add_class_on_scroll();
            //     }                    
            // }                        

        });
    }
    

    windowscreenSize = () => {
        const query = window.matchMedia('(max-width: 1024px)');    
        if (!query.matches){            
            if (window.location.href.indexOf("desktop") === -1)            
              this.redirect('desktop');
        }else{
            if (window.location.href.indexOf("snore") === -1 && window.location.href.indexOf("products") === -1 && window.location.href.indexOf("connect") === -1 
                && window.location.href.indexOf("coaching") === -1 && ( window.location.href.indexOf("/en") > -1 || window.location.href.indexOf("/fr") > -1 ) ) {
                this.redirect('home');
            }
        }
    }
    
    redirect = (pageId) => {
        if (pageId === 'desktop') {
            window.location.href = '/desktop'      
        } else {        
            // if (window.location.href.indexOf("/en") > -1 || window.location.href.indexOf("/fr") > -1)
            //     window.location.href = '/'
            
        }
    }

    render() {    
        return (
            <React.Fragment>          
            </React.Fragment>    
        );
    }

}


export default Home;