import React, { Component } from 'react';

class Desktop extends Component {
  componentDidMount = () => {
    this.windowscreenSize();
  }

  windowscreenSize = () => {
      const query = window.matchMedia('(max-width: 1024px)');    
      if (!query.matches){            
        //   if (window.location.pathname !== '/desktop')
          if (window.location.href.indexOf("desktop") === -1)            
              this.redirect('desktop');
      }else{
          if (window.location.pathname !== '/en' || window.location.pathname !== '/fr')
              this.redirect('home');
      }
  }

  redirect = (pageId) => {
      if (pageId === 'desktop') {
          window.location.href = '/desktop'      
      } else {        
          window.location.href = '/'
      }
  }

  render() {    
      return (
          <React.Fragment>          
          </React.Fragment>    
      );
  }

}

export default Desktop;