import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import Videoplayer from './components/videoplayer/Videoplayer';
import Videoscroll from './components/videoscroll/Videoscroll';
import Carousel from './components/coaching/Carousel';
import Carouselcon from './components/connect/Carouselcon';
import Sensor from './components/snore/sensor';

import * as serviceWorker from './serviceWorker';

if (window.location.pathname === '/en/' || window.location.pathname === '/fr/' || window.location.href.indexOf("coaching") > -1 || window.location.href.indexOf("snore") > -1 
|| window.location.href.indexOf("connect") > -1 ) {
    ReactDOM.render(<Videoplayer />, document.getElementById('react-video-player'));    
}

if (window.location.href.indexOf("snore") > -1){
    ReactDOM.render(<Videoscroll />, document.getElementById('react-video-scroll'));    
    ReactDOM.render(<Sensor />, document.getElementById('snoreSensor'));    
}

if (window.location.href.indexOf("coaching") > -1 || window.location.href.indexOf("connect") > -1) {
    if (window.location.href.indexOf("coaching") > -1) {
        ReactDOM.render(<Carousel />, document.getElementById('carouselApp'));    
    }else{
        ReactDOM.render(<Carouselcon />, document.getElementById('carouselApp'));    
    }
}

ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();