import React, { Component } from 'react';

const scrollNav = document.getElementById("scroll__nav");
const footerCta = document.getElementById("footer-cta");
const googlehome = document.getElementById("score__response");

class Connect extends Component {
    constructor() {      
        super();
        this.state = {                      
            isVisible: false,
        };                
    }
    componentDidMount = () => {
        window.onload = this.transition_text;
        this.windowscreenSize();
        if (window.location.href.indexOf("connect") > -1)
            this.checkwindowScroll();
    }

    transition_text = () => {                        
        let heroIcon = document.getElementsByClassName('hero__icon');        
        let heroTextwhite = document.getElementsByClassName('hero__text--white');
        let heroTextgreen = document.getElementsByClassName('hero__text--green');
        let heroArrow = document.getElementsByClassName('hero__arrow');
        // setAttribute("style", "transition: opacity 0.5s ease-in 2s;opacity: 1");
        heroIcon[0].classList.add('hero__text--transition');        
        heroTextwhite[0].classList.add('hero__text--transitionA');
        heroTextgreen[0].classList.add('hero__text--transitionB');
        heroArrow[0].classList.add('hero__text--transitionC');
    }

    checkwindowScroll = () => {
        let _state = this.state;
        window.addEventListener("scroll", function() {            
            let elementTarget = document.getElementById("connect-hero");
            
            let reselement = document.getElementById("connectScore");
                        
            // const gh_add_class_on_styleop = () => googlehome.style.opacity= 1;            
            // const gh_add_class_on_styletrans_1 = () => googlehome.style.transform= "matrix(1, 0, 0, 1, 0, 0) scale(1.0)";
            // const gh_add_class_on_styletransition = () => googlehome.style.transition= '2.3s'; 
            // const gh_remove_class_on_styleop = () => googlehome.style.opacity= 0;
            // const gh_add_class_on_styletrans_2 = () => googlehome.style.transform= "matrix(0.85, 0, 0, 0.85, 0, 0) scale(0)";    
            let limit =  document.body.offsetHeight - window.innerHeight;        
            
            // if (window.scrollY >= reselement.offsetTop - 50) {                
            /* if (limit - window.scrollY <= 930) {   
                // console.log(`+++++++++> Google Home ${reselement.offsetTop}`);
                gh_add_class_on_styleop();   
                gh_add_class_on_styletransition();             
                gh_add_class_on_styletrans_1();
            }else{
                gh_remove_class_on_styleop();
                gh_add_class_on_styletrans_2();                
            } */
        
        });
    }

    windowscreenSize = () => {
        const query = window.matchMedia('(max-width: 1024px)');    
        if (!query.matches){            
            if (window.location.href.indexOf("desktop") === -1)
                this.redirect('desktop');
        }
        // else{
        //     if (window.location.href.indexOf("connect"))
        //         this.redirect('connect');
        // }
    }
    
    redirect = (pageId) => {
        if (pageId === 'desktop') {
            window.location.href = '/desktop'      
        } else {        
            window.location.href = '/connect'
        }
    }

    render() {    
        return (
            <React.Fragment>          
            </React.Fragment>    
        );
    }

}

export default Connect;