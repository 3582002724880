import React, { Component } from 'react';

const langForm = window.document.getElementById('userlang');

class langswitcher extends Component{    
    
    componentDidMount = () => {        
        
        // Document.getElementsById("default").addEventListener("click", this.initMap('fr'));
        let userlangFr = document.getElementsByClassName('lang-fr');
        let userlangEn = document.getElementsByClassName('lang-en');
        
        userlangFr[0].addEventListener("click", this.switchlang);
        userlangEn[0].addEventListener("click", this.switchlang);
        

        // $( ".user-lang" ).click(function( event ) {
        //     // console.log( "Handler for .submit() called." );            
        //     // console.log( "Now sumbitting form" );
        //     event.preventDefault();
        //     $( "#userlang" ).submit();
        // });
    }

    switchlang = (e) => {        
        e.preventDefault();
        langForm.submit();
    }

    render(){
        return(
            <React.Fragment></React.Fragment>
        );
    }
}

export default langswitcher;