import React, { Component } from 'react'
import ReactPlayer from 'react-player'
import './responsive-player.css'

let poster, video_mp4, video_webm, video_ogv;

class Videoscroll extends Component {

  constructor() {      
    super();
    this.state = {          
        video_mp4: '',
        video_webm: '',
        video_ogv: '',
        poster: '',
        playing: false,
        isloaded: false,
    };                
  }

  componentDidMount = () => {            
    window.addEventListener('scroll', this.onWindowScroll);    
    this.handleReady();
  }  
  
  handleReady = (event) => {    
    video_mp4 = document.getElementsByClassName('snore__scroll')[0].dataset.videoMp4;
    video_webm = document.getElementsByClassName('snore__scroll')[0].dataset.videoWebm;
    video_ogv = document.getElementsByClassName('snore__scroll')[0].dataset.videoOgv;
    poster = document.getElementsByClassName('snore__scroll')[0].dataset.poster;
    this.setState({
      poster, 
      // playing: true,
      isloaded: true,
    });
    const url = [video_mp4, video_ogv, video_webm ];
    this.load(url);
    // console.log(`scroll video ${video_webm}`);
    // console.log(`scroll video poster ${poster}`);
  }

  onWindowScroll = () => {
    let elementTarget = document.getElementById("react-video-scroll");
    let rect = elementTarget.getBoundingClientRect();
    // let viewHeight = Math.max(document.documentElement.clientHeight, window.innerHeight);
    let limit =  document.body.offsetHeight - window.innerHeight;        
    // if ( (rect.bottom >= elementTarget.clientHeight  && rect.bottom < elementTarget.clientHeight + 50 )) {
      if (limit - window.scrollY >= 685 && limit - window.scrollY <= 699) {   
      // console.log("===========>>>>>>>>>>>>>>>>>>>>>>> I am here now !!");   
      
      this.handlePlay();
    }else{
      
      if (this.state.playing) {
        this.handlePlayStop();
      }
    }
    // console.log(`rect top ${rect.top} rect bottom ${rect.bottom}  clientheight ${elementTarget.clientHeight}`);      
  }

  handlePlayPause = () => {
    this.setState({ playing: !this.state.playing })
  }
  
  handlePlay = () => {
    // alert('hello');
    this.player.seekTo(parseFloat(0))
    this.setState({ 
      playing: true,
      poster: false
    })
  }

  handlePlayStop = () => {
    // alert('hello');    
    this.setState({ 
      playing: false      
    })
  }

  ref = player => {
    this.player = player
  }

  load = url => {
    // console.log(url)    
    this.setState({
      url     
    })
  }
  
  render () {    
    const { url, isloaded, playing, poster } = this.state    
    return (
      <div className='player-wrapper scroll' >
        <ReactPlayer 
          ref={this.ref}
          className='react-player scroll'
          url={isloaded?url:null}
          width="100%"
          height="100%"
          // light={poster}
          controls={false}
          playing={playing}
          playIcon={"blank"}
        />
      </div>
    );
  }

}

export default Videoscroll;