import React, { Component } from 'react';
import { gsap } from "gsap";

class Sensor extends Component {
    componentDidMount = () => {

        // gsap
        //     .timeline({
        //         repeat: -1,                
        //         defaults: {
        //         duration: 0.7,     
        //         timeScale: 10,           
        //         }
        //     })
        //     .to('.sensor__circle--6', {
        //         x: 0,
        //         scale: 1.74998,
        //         opacity: 0.250792,                           
        //         ease: "slow( 3.0, 3.0, false)"
        //     })

        //     .to('.sensor__circle--5', {
        //         x: 0,
        //         scale: 1.74998,
        //         opacity: 0.250792,                           
        //         ease: "slow( 3.0, 3.0, false)"
        //     })

        //     .to('.sensor__circle--4', {
        //         x: 0,
        //         scale: 1.74998,
        //         opacity: 0.250019,                                
        //         ease: "slow( 3.0, 3.0, false)"
        //     })
            
        //     .to('.sensor__circle--3', {
        //         x: 0,
        //         scale: 1.74998,
        //         opacity: 0.250792,                           
        //         ease: "slow( 3.0, 3.0, false)"
        //     })

        //     .to('.sensor__circle--2', {
        //         x: 0,
        //         scale: 1.74998,
        //         opacity: 0.250792,                           
        //         ease: "slow( 3.0, 3.0, false)"
        //     })

        //     .to('.sensor__circle--1', {
        //         x: 0,
        //         scale: 1.74998,
        //         opacity: 0.250019,                                
        //         ease: "slow( 3.0, 3.0, false)"
        //     })

        this.leftSensor();
        this.rightSensor();
        
    }

    leftSensor = () => {

        let t1 = gsap.timeline({repeat: -1 });
        let t2 = gsap.timeline({repeat: -1 });
        let t3 = gsap.timeline({repeat: -1 });
        t1.to('.sensor__circle--6', {x: 0, duration: 1.3,  delay: 0 , scale: 1.74998, opacity: 0.250792, ease: "slow( 6.0, 6.0, false)"});
        t2.to('.sensor__circle--5', {x: 0, duration: 2.0,  delay: 0.1 , scale: 1.74998, opacity: 0.250792, ease: "slow( 6.0, 6.0, false)"});
        t3.to('.sensor__circle--4', {x: 0, duration: 2.5,  delay: 0.3 , scale: 1.74998, opacity: 0.250792, ease: "slow( 6.0, 6.0, false)"});        
        // gsap
        //     .timeline({
        //         repeat: -1,   
        //         delay: 0,             
        //         defaults: {
        //         duration: 0.5,                          
        //         }
        //     })
        //     .to('.sensor__circle--6', {
        //         x: 0,
        //         scale: 1.74998,
        //         opacity: 0.250792,                           
        //         ease: "slow( 3.0, 3.0, false)"
        //     })

        //     .to('.sensor__circle--5', {
        //         x: 0,
        //         scale: 1.74998,
        //         opacity: 0.250792,                           
        //         ease: "slow( 3.0, 3.0, false)"
        //     })

        //     .to('.sensor__circle--4', {
        //         x: 0,
        //         scale: 1.74998,
        //         opacity: 0.250019,                                
        //         ease: "slow( 3.0, 3.0, false)"
        //     })
    }

    rightSensor = () => {
        let t1 = gsap.timeline({repeat: -1 });
        let t2 = gsap.timeline({repeat: -1 });
        let t3 = gsap.timeline({repeat: -1 });
        t1.to('.sensor__circle--3', {x: 0, duration: 1.3,  delay: 0 , scale: 1.74998, opacity: 0.250792, ease: "slow( 6.0, 6.0, false)"});
        t2.to('.sensor__circle--2', {x: 0, duration: 2.0,  delay: 0.1 , scale: 1.74998, opacity: 0.250792, ease: "slow( 6.0, 6.0, false)"});
        t3.to('.sensor__circle--1', {x: 0, duration: 2.5,  delay: 0.3 , scale: 1.74998, opacity: 0.250792, ease: "slow( 6.0, 6.0, false)"});        
    }
    
    render () {    
        return (
            <React.Fragment>
            <img className="sensor__image" src="/static/img/snore/panel-snore-sensors-1a4884c00e.jpg" alt=""/>
            <div className="sensor__circle sensor__circle--back">
                <svg id="icon-circle" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100">
                <title>icon-circle</title>
                <circle cx="50" cy="50" r="48" stroke="#B1D56E" strokeWidth="2" strokeDasharray="1 5"
                    strokeLinecap="round" fill="transparent"></circle>
                </svg>
            </div>
            <div className="sensor__circle sensor__circle--back sensor__circle--4"
                style={{zIndex: 0, opacity: 0.91906, transform: "translate3d(0px, 0px, 0px) scale(1.0, 1.0)"}}>
                <svg id="icon-circle" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100">
                <title>icon-circle</title>
                <circle cx="50" cy="50" r="48" stroke="#B1D56E" strokeWidth="2" strokeDasharray="1 5"
                    strokeLinecap="round" fill="transparent"></circle>
                </svg>
            </div>
            <div className="sensor__circle sensor__circle--back sensor__circle--5"
                style={{zIndex: 0, opacity: 0.91906, transform: "translate3d(0px, 0px, 0px) scale(1.0, 1.0)"}}>
                <svg id="icon-circle" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100">
                <title>icon-circle</title>
                <circle cx="50" cy="50" r="48" stroke="#B1D56E" strokeWidth="2" strokeDasharray="1 5"
                    strokeLinecap="round" fill="transparent"></circle>
                </svg>
            </div>
            <div className="sensor__circle sensor__circle--back sensor__circle--6"
                style={{zIndex: 0, opacity: 0.91906, transform: "translate3d(0px, 0px, 0px) scale(1.0, 1.00)"}}>
                <svg id="icon-circle" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100">
                <title>icon-circle</title>
                <circle cx="50" cy="50" r="48" stroke="#B1D56E" strokeWidth="2" strokeDasharray="1 5"
                    strokeLinecap="round" fill="transparent"></circle>
                </svg>
            </div>


            <img className="sensor__image sensor__image--front"
            src="/static/img/snore/panel-snore-sensors-front-8e7ab38ac9.png" alt="" />
            <div className="sensor__circle sensor__circle--front">
            <svg id="icon-circle" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100">
                <title>icon-circle</title>
                <circle cx="50" cy="50" r="48" stroke="#B1D56E" strokeWidth="2" strokeDasharray="1 5"
                strokeLinecap="round" fill="transparent"></circle>
            </svg>
            </div>
            <div className="sensor__circle sensor__circle--front sensor__circle--1"
            style={{zIndex: 0, opacity: 0.91906, transform: "translate3d(0px, 0px, 0px) scale(1.0, 1.0)"}}>
            <svg id="icon-circle" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100">
                <title>icon-circle</title>
                <circle cx="50" cy="50" r="48" stroke="#B1D56E" strokeWidth="2" strokeDasharray="1 5"
                strokeLinecap="round" fill="transparent"></circle>
            </svg>
            </div>
            <div className="sensor__circle sensor__circle--front sensor__circle--2"
            style={{zIndex: 0, opacity: 0.91906, transform: "translate3d(0px, 0px, 0px) scale(1.0, 1.0)"}}>
            <svg id="icon-circle" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100">
                <title>icon-circle</title>
                <circle cx="50" cy="50" r="48" stroke="#B1D56E" strokeWidth="2" strokeDasharray="1 5"
                strokeLinecap="round" fill="transparent"></circle>
            </svg>
            </div>
            <div className="sensor__circle sensor__circle--front sensor__circle--3"
            style={{zIndex: 0, opacity: 0.91906, transform: "translate3d(0px, 0px, 0px) scale(1.0, 1.0)"}}>
            <svg id="icon-circle" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100">
                <title>icon-circle</title>
                <circle cx="50" cy="50" r="48" stroke="#B1D56E" strokeWidth="2" strokeDasharray="1 5"
                strokeLinecap="round" fill="transparent"></circle>
            </svg>
            </div>     
            </React.Fragment>              
        );
    }

}

export default Sensor;